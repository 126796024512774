import { Injectable, inject, WritableSignal, signal } from '@angular/core';
import { FirebaseError } from '@angular/fire/app';
import { Auth, GoogleAuthProvider, User, UserCredential, signInWithRedirect, signInWithPopup, getRedirectResult } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

export const firebaseRedirectAuthWrapper = {
  signInWithRedirect,
};

export const firebasePopupAuthWrapper = {
  signInWithPopup,
};

export const getRedirectResultWrapper = {
  getRedirectResult,
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly auth: Auth = inject(Auth);
  private readonly googleAuthProvider: GoogleAuthProvider = inject(GoogleAuthProvider);
  private readonly router: Router = inject(Router);
  readonly token: WritableSignal<string | null> = signal(null);
  readonly user: WritableSignal<User | null> = signal(null);

  constructor() {
    this.auth.onAuthStateChanged(async user => (user ? this.user.set(user) : await this.logout()));
    this.auth.onIdTokenChanged(async user => {
      try {
        this.token.set((await user?.getIdToken()) ?? null);
      } catch (error) {
        console.error('Error getting ID token:', error);
        await this.logout();
      }
    });
  }

  authenticateWithGoogle(): Promise<UserCredential | void> {
    return environment.authMode === 'redirect'
      ? firebaseRedirectAuthWrapper.signInWithRedirect(this.auth, this.googleAuthProvider)
      : firebasePopupAuthWrapper.signInWithPopup(this.auth, this.googleAuthProvider);
  }

  handleRedirectResult(): Promise<UserCredential | null> {
    return getRedirectResultWrapper.getRedirectResult(this.auth);
  }

  async logout(): Promise<void> {
    try {
      await this.auth.signOut();
      this.user.set(null);
      this.token.set(null);
      this.router.navigateByUrl('auth/authenticate');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  }

  getErrorMessage(error: FirebaseError): string {
    const errorMessages: Record<string, string> = {
      'auth/popup-closed-by-user': 'Authentication cancelled. Please try again.',
      'auth/popup-blocked': 'Pop-up blocked by browser. Please allow pop-ups for authentication.',
      'auth/cancelled-popup-request': 'Multiple pop-up requests detected. Please try again.',
      'auth/account-exists-with-different-credential': 'An account already exists with the same email but different sign-in credentials.',
      'auth/network-request-failed': 'Network error. Please check your connection and try again.',
      'auth/user-disabled': 'This account has been disabled. Please contact support.',
      'auth/operation-not-allowed': 'Google sign-in is not enabled. Please contact support.',
      'auth/invalid-email': 'The email address is not valid.',
      'auth/user-not-found': 'No account found with this email address.',
      'auth/unauthorized-domain': 'This domain is not authorized for Google authentication.',
      'auth/internal-error': 'An internal error occurred. Please try again later.',
    };

    return errorMessages[error.code] || 'An unexpected error occurred during authentication. Please try again.';
  }
}
